<template>
    <v-container>
        <v-row>
            <v-col :sm="12" :md="8" :lg="6">
                <v-card>
                    <v-card-title>
                        Werkbonnen
                    </v-card-title>
                    <v-card-text>
                        <WorkOrderList :canSearch="true" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import WorkOrderList from "@/components/workOrder/WorkOrderList";

export default {
    name: "WorkOrderListView",
    components: {WorkOrderList},
}
</script>

<style scoped>

</style>
